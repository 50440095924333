import AuthService from '@/services/authService'
import PainelClientService from '@/services/bussines/painelCliente.service'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'


const state = {
    accessToken: Cookies.get('access_token') || null,
    isAuthenticated: !!Cookies.get('access_token'),
    userProfile: Cookies.get('user_profile') ? JSON.parse(Cookies.get('user_profile')) : null,
}

const mutations = {
    SET_ACCESS_TOKEN(state, token) {
        state.accessToken = token
        state.isAuthenticated = !!token
        // console.log(state)
        if (token) {
          Cookies.set('access_token', token, { secure: true, sameSite: 'Strict', expires: new Date(new Date().getTime() + 180 * 60 * 1000) })
        } else {
          Cookies.remove('access_token')
        }
    },
    SET_USER_PROFILE(state, profile) {
        state.userProfile = profile
        //const expirationDate = new Date(new Date().getTime() + 180 * 60 * 1000);

        if (profile) {
            Cookies.set('user_profile', JSON.stringify(profile), { secure: true, sameSite: 'Strict',expires: new Date(new Date().getTime() + 180 * 60 * 1000) })
          } else {
            Cookies.remove('user_profile')
          }
    },
}

const actions = {
    async login({ commit }, { username, password }) {

        commit('SET_ACCESS_TOKEN', null)
        commit('SET_USER_PROFILE', null)

        let response = null

        try{
            response = await AuthService.login(username, password)
            commit('SET_ACCESS_TOKEN', response.id_token)

        }catch(error){
            commit('SET_ACCESS_TOKEN', null)
            commit('SET_USER_PROFILE', null)

            const ex = error.response.data
            console.error('Erro de login:', ex)
            return ex
        }

        if(response){
            const userManager = jwtDecode(response.id_token)
            const profile = {
                jti: userManager.jti,
                id: userManager.sub,
                email: userManager.email,
                role: userManager.role,
                scope: userManager.scope,
                exp:  userManager.exp,
            }
            commit('SET_USER_PROFILE', profile)
        }

        return response
    }, 
    async loginClient({ commit }, { username, password }) {
        commit('SET_ACCESS_TOKEN', null)
        commit('SET_USER_PROFILE', null)

        let response = null

        try{
            response = await AuthService.loginClient(username.trim(), password.trim())

            commit('SET_ACCESS_TOKEN', response.id_token)
        }catch(error){
            commit('SET_ACCESS_TOKEN', null)
            commit('SET_USER_PROFILE', null)

            const ex = error.response.data
            console.error('Erro de login:', ex)
            return ex
        }

        if(response){
            const userManager = jwtDecode(response.id_token)

            const customerInfo = await PainelClientService.CustomerInfo()

            const profile = {
                jti: userManager.jti,
                id: userManager.sub,
                email: userManager.email,
                role: userManager.role,
                scope: userManager.scope,
                exp:  new Date(userManager.exp).toUTCString(),
                name: customerInfo != null ? customerInfo.Name : "Cliente"  
            }
            commit('SET_USER_PROFILE', profile)
        }
        return response
    },
    async changePassword({ commit }, { currentPassword, newPassword }) {
        try {
            const response = await AuthService.changePassword(currentPassword, newPassword)
           
            return response
        } catch (error) {
            const ex = error.response.data
            throw ex
        }
    },
    logout({ commit }) {
        commit('SET_ACCESS_TOKEN', null)
        commit('SET_USER_PROFILE', null)
    },
}

const getters = {
    isAuthenticated: (state) => !!state.accessToken,
    userRole: (state) => state.userProfile ? state.userProfile.role : null,
    userOnline: (state) => state.userProfile ? state.userProfile : null,
    accessToken: (state) => state.accessToken,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
import { stringify, parse } from 'flatted'

const _data_source = localStorage.getItem("fin_extrato_lista") ? parse(localStorage.getItem("fin_extrato_lista")) : []
const _data_filter = localStorage.getItem("fin_filter") ? parse(localStorage.getItem("fin_filter")) : {
  Conta_rid: null,
  DateRef: null
}

// console.log("selectedDate",selectedDate1)
// console.log("selectedDate",_data_filter)

const state = {
  extrato_lista: _data_source,
  filterAtual: _data_filter
}


// getters
const getters = {
  extratoLista: state => state.extrato_lista,
  filterAtual: state => state.filterAtual
}

// actions
const actions = {

  setFilter({ commit },value) {
    commit("setFilter", value)
  },

  setDataExtrato({ commit }, value) {
    commit('setDataExtrato', value)
  },
  limpaDataExtrato({ commit }, value) {
    commit('clearExtrato', value)
  },
  limpaFilter({ commit }) {
    commit('clearFilter')
  },
}

// mutations
const mutations = {

  setFilter(state, resp) {
    state.filterAtual = resp

    const jsonData1 = stringify(state.filterAtual)
    localStorage.setItem('fin_filter', jsonData1)
  },
  setDataExtrato(state, value,) {
    state.extrato_lista = value
    const jsonData = stringify(state.extrato_lista)

    localStorage.setItem('fin_extrato_lista', jsonData)
  },
  clearExtrato(state){
    state.extrato_lista = []
    localStorage.removeItem("fin_extrato_lista")
  },
  clearFilter(){
    localStorage.removeItem("fin_filter")
  },
  setSelectedDate(state,value){
   
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const isAuthenticated = store.getters['authOidc/isAuthenticated']
  const userRole = store.getters['authOidc/userRole']
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const allowedRoles = to.meta.roles || []

  if (requiresAuth) {
    
    const hasAccess = userRole != null ? allowedRoles.some((role) => userRole.includes(role)) : false

    if (to.path === '/login' && from.path === '/login') {
      return next(false) // Evita navegação redundante
    }

    if (!isAuthenticated ||  !hasAccess) {
      next({ path: '/access-denied' })
    } else {
      next()
    }
  } else {
    next()
  }

  //const permissions = ['Avancado']

  // if (to.matched.some(record => record.meta.requiresAuth)) {

  //   if (store.getters['auth/isLoggedIn'] && permissions.indexOf(store.getters['auth/userTipo']) != -1)
  //     return next()
  //   else
  //     return next('/login')
  // } else
  //   return next()

  //return next()
})

export default router
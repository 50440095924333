const metaAdmin = {
    requiresAuth: true,
    roles: ['Admin']
}

export default [
    {
        path: '/gerencial',
        name: 'gerencial',
        component: () => import('../views/Gerencial/GerencialLayout.vue'),
        meta: metaAdmin,
        redirect: 'gerencial/dashboard',
        children: [
            {
                path: '/gerencial/account',
                name: 'Perfil usuário',
                component: () => import('../components/Gerencial/PerfilUser/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/configuracoes',
                name: 'Configuracoes',
                component: () => import('../components/Gerencial/Configuracoes/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/dashboard',
                name: 'gestao-dashboard',
                component: () => import('../views/Gerencial/DashboardLayout.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/produtos',
                name: 'Produtos',
                component: () => import('../components/Gerencial/Cadastros/Produtos/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/usuarios',
                name: 'Funcionários/Usuários',
                component: () => import('../components/Gerencial/Cadastros/Usuarios/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/vendedores',
                name: 'Vendedores',
                component: () => import('../components/Gerencial/Cadastros/Vendedores/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/vendedores/relatorio',
                name: 'Vendedores Relatorio',
                component: () => import('../components/Gerencial/Cadastros/Vendedores/RelatorioVendedor/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/clientes',
                name: 'Clientes',
                component: () => import('../components/Gerencial/Cadastros/Clientes/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/emails-cliente',
                name: 'Emails Cliente',
                component: () => import('../components/Gerencial/Cadastros/Clientes/SendEmails/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/clientes/contratos',
                name: 'Contratos Clientes',
                component: () => import('../components/Gerencial/Cadastros/Clientes/ContratosCliente/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/clientes/:rid',
                name: 'Cliente - Detalhes',
                component: () => import('../components/Gerencial/Cadastros/Clientes/Detalhe/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/investcoach',
                name: 'Relatorio de investCoach',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelInvestCoach/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/pedidos',
                name: 'Pedidos',
                component: () => import('../components/Gerencial/Pedidos/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/pedidos/:rid',
                name: 'Pedidos - Detalhe',
                component: () => import('../components/Gerencial/Pedidos/PedidoDetalhe/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/contas-receber',
                name: 'Contas à Receber',
                component: () => import('../components/Gerencial/ContasReceber/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/contas-receber/:rid',
                name: 'Contas à Receber - Detalhe',
                component: () => import('../components/Gerencial/ContasReceber/Detalhe/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cobrancas',
                name: 'Cobranças',
                component: () => import('../components/Gerencial/Cobrancas/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cobrancas/:rid',
                name: 'Cobranças - Detalhes',
                component: () => import('../components/Gerencial/Cobrancas/DetalheCobranca/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/master',
                name: 'Operações Master',
                component: () => import('../components/Gerencial/Operacoes/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/gerar-ordem',
                name: 'Gerar ordem',
                component: () => import('../components/Gerencial/Operacoes/OperacaoOrdem/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/options-price',
                name: 'Options Price',
                component: () => import('../components/Gerencial/Operacoes/OptionsPrice/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/rascunho-ordens',
                name: 'Rascunho ordens',
                component: () => import('../components/Gerencial/Operacoes/RascunhoOrdem/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/rascunho-ordens-email/',
                name: 'E-mail Financeiro',
                component: () => import('../components/Gerencial/Operacoes/EmailFinanOrdem/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/enviar-ordens-email/:rascunho_rid',
                name: 'Enviar E-mail Financeiro',
                component: () => import('../components/Gerencial/Operacoes/DetailEmailOrdem/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/cliente',
                name: 'Operações Cliente',
                component: () => import('../components/Gerencial/Operacoes/OperacoesCliente/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/clientes/relatorio-performace/:rid',
                name: 'Relatorio de Performace',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelatorioPerformance/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/ordens-mensal/',
                name: 'Relatorio de Ordens mensal',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelOrdensMensal/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/controle-lft-logs/',
                name: 'Controle LFT - Logs',
                component: () => import('../components/Gerencial/Cadastros/Clientes/ControleLFTLogs/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/renovacao-lft/',
                name: 'Renovação LFT',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RenovacaoLFT/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/clientes/relatorio-desempenho/:rid',
                name: 'Relatorio de Desempenho',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelatorioDesempenho/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/clientes/relatorio-dados/:rid',
                name: 'Relatorio de Dados',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelatorioDados/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/acompanhar',
                name: 'Acompanhar Ordens',
                component: () => import('../components/Gerencial/Operacoes/AcompanharOrdens/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/acompanhar/:rid',
                name: 'Acompanhar Ordens - Detalhes',
                component: () => import('../components/Gerencial/Operacoes/AcompanharOrdens/DetalheOrdem/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/ativos-referencia',
                name: 'Ativos de Refência',
                component: () => import('../components/Gerencial/Operacoes/AtivosReferencia/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/operacoes/simulacao-rolagem',
                name: 'Simulação Rolagem',
                component: () => import('../components/Gerencial/Operacoes/SimulacaoRolagem/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/cadastros/taxa-selic',
                name: 'Taxa Selic',
                component: () => import('../components/Gerencial/Cadastros/TaxaSelic/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/nota-fiscal',
                name: 'Nota Fiscal',
                component: () => import('../components/Gerencial/NotaFiscal/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/nota-fiscal/:rid',
                name: 'Nota Fiscal Detalhe',
                component: () => import('../components/Gerencial/NotaFiscal/NotaDetail/index.vue'),
                meta: metaAdmin,
            },
            {
                path: '/gerencial/agenda-cursos',
                name: 'Agenda de Cursos',
                component: () => import('../components/Gerencial/AgendaCursos/index.vue'),
                meta: metaAdmin,
            },
            
            {
                path: '/gerencial/painel-selfinvest',
                name: 'Painel Selfinvest',
                component: () => import('../views/Gerencial/PainelSelfLayout.vue'),
                meta: metaAdmin,             
            },

            // CONSILIACAO

            {
                path: '/gerencial/banco-conciliacao',
                name: 'Banco e conciliação',
                component: () => import('../views/Gerencial/ConciliacaoLayout.vue'),
                meta: metaAdmin,
                redirect: 'gerencial/categorias',
                children: [
                    {
                        path: '/gerencial/banco-conciliacao/categorias',
                        name: 'Categorias',
                        component: () => import('../components/Gerencial/BancoConciliacao/Categorias/index.vue'),
                        meta:metaAdmin,
                    },
                    {
                        path: '/gerencial/banco-conciliacao/contas',
                        name: 'Contas',
                        component: () => import('../components/Gerencial/BancoConciliacao/Contas/index.vue'),
                        meta:metaAdmin,
                    },
                    {
                        path: '/gerencial/banco-conciliacao/detalhe-conta/:rid',
                        name: 'Detalhe Contas',
                        component: () => import('../components/Gerencial/BancoConciliacao/DetalheConta/index.vue'),
                        meta:metaAdmin,
                        redirect: '/gerencial/banco-conciliacao/detalhe-conta/:rid/receitas',
                        children: [
                            {
                                path: '/gerencial/banco-conciliacao/detalhe-conta/:rid/despesas',
                                name: 'Despesas',
                                component: () => import('../components/Gerencial/BancoConciliacao/ContaPagar/index.vue'),
                                meta: metaAdmin,
                            },
                            {
                                path: '/gerencial/banco-conciliacao/detalhe-conta/:rid/receitas',
                                name: 'Receitas',
                                component: () => import('../components/Gerencial/BancoConciliacao/ContaReceber/index.vue'),
                                meta: metaAdmin,
                            },
                            {
                                path: '/gerencial/banco-conciliacao/detalhe-conta/:rid/conciliacao',
                                name: 'Conciliação',
                                component: () => import('../components/Gerencial/BancoConciliacao/Conciliacao/index.vue'),
                                meta: metaAdmin,
                            },
                            {
                                path: '/gerencial/banco-conciliacao/detalhe-conta/:rid/extrato-financeiro',
                                name: 'ExtratoConta',
                                component: () => import('../components/Gerencial/BancoConciliacao/ExtratoConta/index.vue'),
                                meta: metaAdmin,
                            },
                        ]
                    },
                    {
                        path: '/gerencial/banco-conciliacao/conta-pagar/:conta_rid',
                        name: 'Contas a pagar',
                        component: () => import('../components/Gerencial/BancoConciliacao/ContaPagar/index.vue'),
                        meta:metaAdmin,
                    },
                ]
            },
        ]
    },
]